const auditStatus = [
  {
    value: "0",
    label: "待审核",
  },
  {
    value: "1",
    label: "审核通过",
  },
  {
    value: "2",
    label: "审核驳回",
  },
];
const cancelStatus = [
  {
    value: "0",
    label: "未取消",
  },
  {
    value: "1",
    label: "已取消",
  },
];
export { auditStatus, cancelStatus };
