let origin = "https://future.zhuneng.cn";
//查询公益捐赠列表
const getDonateList = `/gateway/hc-serve/manageapi/donate/donateList`;
//公益捐赠详情
const donateDetail = `/gateway/hc-serve/manageapi/donate/donateDetail`;
//公益捐赠审核
const donateAudit = `/gateway/hc-serve/manageapi/donate/donateAudit`;
//公益捐赠确认
const donateConfirm = `/gateway/hc-serve/manageapi/donate/donateConfirm`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;

export {
  getDonateList,
  donateDetail,
  donateConfirm,
  donateAudit,
  getCommunityListUrl,
};
